import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
    <Layout>
        <SEO title="Sobre Ugni" />
        <div className="about-widget-area pt-50 pb-70">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-widget-img">
                            <StaticImage src="../images/about/about-img4.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-widget-content">
                            <div className="section-title">
                                <span className="sp-before sp-after">Sobre nosotros</span>
                                <h2>Fábrica de tecnología de calidad</h2>
                            </div>
                            <h3>Tenemos más de 10 años de experiencia acompañando a múltiples empresas en un proceso de transformación digital</h3>
                            <p>A lo largo del tiempo, hemos tenido el placer de colaborar con la industria del sector transporte, gobierno, logística y de alimentos, en todos ellos caracterizandonos por nuestros pilares:</p>
                            <div className="about-widget-list">
                                <ul>
                                    <li>
                                        <i className="bx bx-check"></i>
                                        <span>01</span>
                                        <p>
                                            Altos estándares de profesionalismo e integridad. Establecimiento de relaciones de trabajo cercanas y honestas.
                                        </p>
                                    </li>
                                    <li>
                                        <i className="bx bx-check"></i>
                                        <span>02</span>
                                        <p>
                                            Diferentes formas de pensar, tanto adentro como afuera de la caja, la mejor solución de un problema no es siempre la más compleja.
                                        </p>
                                    </li>
                                    <li>
                                        <i className="bx bx-check"></i>
                                        <span>03</span>
                                        <p>
                                            Competencia multidisciplinaria. Siempre aspirando a hacer mejor las cosas, aprendiendo constantemente y aplicandolo en el día a día.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="counter-area-two pt-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <div className="counter-card">
                            <h3>11 años</h3>
                            <p>En el mercado</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <div className="counter-card">
                            <h3>100%</h3>
                            <p>Satisfacción de clientes</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <div className="counter-card">
                            <h3>15+</h3>
                            <p>Miembros</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <div className="counter-card">
                            <h3>127+</h3>
                            <p>Proyectos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="counter-shape-top">
                <StaticImage src="../images/counter/counter-shape.png" alt="Images" />
            </div>
        </div>

        <div className="service-list-area pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sp-before sp-after">Servicios</span>
                    <h2>Nuestros pilares</h2>
                </div>
                <div className="row pt-45">
                    <div className="col-lg-6 col-md-6">
                        <div className="service-list-content">
                            <h3>Nustra misión</h3>
                            <p>Llevar soluciones de tecnología de calidad para clientes de todos los tamaños en LA.</p>
                            <ul>
                                <li>
                                    <i className="bx bx-check"></i>
                                Aplicaciones móviles
                                </li>
                                <li>
                                    <i className="bx bx-check"></i>
                                Automatización de procesos
                                </li>
                                <li>
                                    <i className="bx bx-check"></i>
                                Monitoreo y analítica
                                </li>
                                <li>
                                    <i className="bx bx-check"></i>
                                Inteligencia artificial
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="service-list-content">
                            <h3>¿Quiénes sómos?</h3>
                            <p>Un equipo de alta competitividad dedicado a aprender y colaborar mientras solucionan problemas del mundo real.</p>
                            <ul>
                                <li>
                                    <i className="bx bx-check"></i>
                                Desarrolladores
                                </li>
                                <li>
                                    <i className="bx bx-check"></i>
                                Diseñadores
                                </li>
                                <li>
                                    <i className="bx bx-check"></i>
                                Project managers
                                </li>
                                <li>
                                    <i className="bx bx-check"></i>
                                Científicos de datos
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="work-area-two pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sp-before sp-after">Proceso de trabajo</span>
                    <h2 className="h2-color2">Simple y limpio</h2>
                </div>
                <div className="row pt-45">
                    <div className="col-lg-4">
                        <div className="work-item-list">
                            <ul>
                                <li className="text-right">
                                    <h3>Definición del problema <span>1</span></h3>
                                    <p>Alcance, interesados e involucrados en el proceso</p>
                                </li>
                                <li className="text-right">
                                    <h3>Obtención del la información <span>2</span></h3>
                                    <p>Fuentes de información, sensórica, sistemas de terceros o propios. </p>
                                </li>
                                <li className="text-right">
                                    <h3>Procesamiento de la información <span>3</span></h3>
                                    <p>Análisis del flujo de los datos </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="work-img-2">
                            <StaticImage src="../images/work-img.png" alt="Images" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="work-item-list-2">
                            <ul>
                                <li>
                                    <h3><span>4</span>Planeación de productos</h3>
                                    <p>Planteamiento de sprints usando metodologías ágiles </p>
                                </li>
                                <li>
                                    <h3><span>5</span>Análisis de resultados</h3>
                                    <p>Revisión de productos, entregas parciales </p>
                                </li>
                                <li>
                                    <h3><span>6</span>Iteración</h3>
                                    <p>Teniendo en cuenta las lecciones aprendidas. </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="history-area pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sp-before sp-after">Nuestra historia</span>
                    <h2>Datos sobre la compañía</h2>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="history-content">
                            <h2>Resultados</h2>
                            <p>Nuestros productos responden a las necesidades y el entorno cambiante de los negocios. Contamos con grupos de Desarrollo a la Medida, Software factory, y mantenimiento de Aplicaciones que están a su disposición. </p>
                            <a href="/contact-us/" className="default-btn">
                                Contacto
                                <i className="bx bx-plus"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="history-img">
                            <StaticImage src="../images/history-img.png" alt="Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="brand-logo-area  pt-100">
            <div className="container-fluid">
                <div className="container-max">
                    <div className="brand-logo-slider owl-carousel owl-theme">
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo1.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo2.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo3.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo4.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo5.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo6.png" alt="Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default AboutPage
